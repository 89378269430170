<template>
    <div :key="pageKey">
        <v-data-table
                :headers="headers"
                :items="PackingListItems"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-pagination
                disable-sort
                hide-default-header
                hide-default-footer
                item-key="index"
                single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-12-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-10-pct">{{ headers[2].text }}</th>
                    <!-- <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[3].text }}</th> -->
                    <!-- <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[4].text }}</th> -->
                    <!-- <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[5].text }}</th> -->
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-15-pct">{{ headers[3].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-7-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-7-pct">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-10-pct">{{ headers[6].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-10-pct">{{ headers[7].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct">{{ headers[8].text }}</th>
                </tr>
            </template>
            <template v-slot:item.PackingListItem.container_id="{ item }">
                <v-select
                        :items="Pldo__Containers"
                        :placeholder="$t('message.container')"
                        :value="item.PackingListItem.container"
                        class="custom pa-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        item-text="Container.title"
                        item-value="Container.title"
                        return-object
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.container_id',$event)"
                >
                    <template v-slot:item="{ item }">
                        <div class="d-flex flex-column py-1">
                            <span class="font-weight-bold">{{ item.Container.title }}</span>
                            <span class="font-sm">{{ $t('message.sealNo') + ': ' +  (item.Container.sealno != '' && item.Container.sealno != null ? item.Container.sealno : $t('message.na')) }}</span>
                        </div>
                    </template>
                </v-select>
            </template>
            <template v-slot:item.PackingListItem.salesdescription_id="{item}">
                <v-select
                        :items="salesDescriptions"
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.PackingListItem.salesdescription_id"
                        class="custom pa-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        item-text="Salesdescription.title"
                        item-value="Salesdescription.id"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.salesdescription_id',$event)"
                >
                    <template v-slot:selection="{ item }">
                        <span>{{ item.Salesdescription.title }}</span>
                    </template>
                </v-select>
            </template>
            <template v-slot:item.PackingListItem.grade_id="{item}">
                <v-autocomplete
                        :items="allGrades"
                        :placeholder="$t('message.startTypingToSearch')"
                        :value="item.PackingListItem.grade_id"
                        class="custom pa-0 bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        item-text="Grade.title"
                        item-value="Grade.id"
                        return-object
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.grade_id',$event)"
                >
                </v-autocomplete>
                <v-text-field
                    :value="item.PackingListItem.type"
                    :placeholder="$t('message.type')"
                    class="custom pa-0 mt-6px bg-lightgrey force-text-left"
                    clearable
                    dense
                    hide-details
                    @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.type',$event)"
                >
                </v-text-field>
            </template>
            <!-- <template v-slot:item.PackingListItem.type="{item}"> -->
            <!-- </template> -->
            <template v-slot:item.PackingListItem.thickness="{ item }">
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold pa-0 mr-2 mt-2">T: </span>
                    <v-text-field
                        :placeholder="$t('message.thickness')"
                        :value="item.PackingListItem.thickness"
                        class="custom pa-0 mt-1 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.thickness',$event)"
                    >
                    </v-text-field>
                    <v-select
                        :items=uofmDimensions
                        :value="item.PackingListItem.smeasurement_id"
                        class="custom pa-0 bg-lightgrey ml-2"
                        dense
                        hide-details
                        item-text="Measurement.title"
                        item-value="Measurement.id"
                        style="width: 75px !important;"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.smeasurement_id',$event)"
                    >
                    </v-select>
                </div>
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold pa-0 mr-2 mt-2">W: </span>
                    <v-text-field
                        :placeholder="$t('message.width')"
                        :value="item.PackingListItem.width"
                        class="custom pa-0 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.width',$event)"
                    >
                    </v-text-field>
                    <v-select
                        :items=uofmDimensions
                        :value="item.PackingListItem.wmeasurement_id"
                        class="custom pa-0 bg-lightgrey ml-2"
                        dense
                        hide-details
                        item-text="Measurement.title"
                        item-value="Measurement.id"
                        style="width: 75px !important;"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.wmeasurement_id',$event)"
                    >
                    </v-select>
                </div>
                <div class="d-flex flex-row align-center">
                    <span class="font-weight-bold pa-0 mr-2 mt-2">L: </span>
                    <v-text-field
                        :placeholder="$t('message.length')"
                        :value="item.PackingListItem.length"
                        class="custom pa-0 bg-lightgrey force-text-left"
                        clearable
                        dense
                        hide-details
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.length',$event)"
                    >
                    </v-text-field>
                    <v-select
                        :items=uofmDimensions
                        :value="item.PackingListItem.lmeasurement_id"
                        class="custom pa-0 bg-lightgrey ml-2"
                        dense
                        hide-details
                        inputmode=""
                        item-text="Measurement.title"
                        item-value="Measurement.id"
                        style="width: 75px !important;"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.lmeasurement_id',$event)"
                    >
                    </v-select>
                </div>
            </template>
            <template v-slot:item.PackingListItem.bundles="{ item }">
                <v-text-field
                        :placeholder="$t('message.bundles')"
                        :value="item.PackingListItem.bundles"
                        class="custom pa-0 bg-lightgrey"
                        dense
                        hide-details
                        type="number"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.bundles',$event)"
                >
                </v-text-field>
            </template>
            <template v-slot:item.PackingListItem.pieces="{ item }">
                <v-text-field
                        :placeholder="$t('message.pieces')"
                        :value="item.PackingListItem.pieces"
                        class="custom pa-0 bg-lightgrey"
                        dense
                        hide-details
                        type="number"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.pieces',$event)"
                >
                </v-text-field>
            </template>
            <template v-slot:item.PackingListItem.area="{ item }">
                <div class="d-flex flex-row align-center">
                    <v-text-field
                        :placeholder="$t('message.area')"
                        :value="item.PackingListItem.area"
                        class="custom pa-0 bg-lightgrey"
                        dense
                        hide-details
                        type="number"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.area',$event)"
                    >
                    </v-text-field>
                    <v-select
                        :items=uofmAreas
                        :value="item.PackingListItem.ameasurement_id"
                        cache-items
                        class="custom pa-0 bg-lightgrey ml-2"
                        dense
                        hide-details
                        item-text="Measurement.title"
                        item-value="Measurement.id"
                        style="width: 75px !important;"
                        @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.ameasurement_id',$event)"
                    >
                    </v-select>
                </div>
            </template>
            <template v-slot:item.PackingListItem.volume="{ item }">
                <div class="d-flex flex-row align-center">
                    <v-text-field
                            :placeholder="$t('message.volume')"
                            :value="item.PackingListItem.volume"
                            class="custom pa-0 bg-lightgrey"
                            dense
                            hide-details
                            type="number"
                            @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.volume',$event)"
                    >
                    </v-text-field>
                    <v-select
                            :items=uofmVolumes
                            :value="item.PackingListItem.shippedmeasurement_id"
                            cache-items
                            class="custom pa-0 bg-lightgrey ml-2"
                            dense
                            hide-details
                            item-text="Measurement.title"
                            item-value="Measurement.id"
                            style="width: 75px !important;"
                            @change="updatePackingListItemRowHandle( item.index, 'PackingListItem.shippedmeasurement_id',$event)"
                    >
                    </v-select>
                </div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="text-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.PackingListItem.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-5 pt-5 px-0 d-flex justify-start">
                        <v-btn
                                @click="addPackingListItemRow(statePrefix)"
                                color="success"
                                small
                        >{{ PackingListItems.length == 0 ? $t('message.addItem') : $t('message.addMore') }}
                        </v-btn>
                        <v-btn
                                :loading="resettingPackingListItems"
                                class="ml-3"
                                color="default"
                                small
                                v-if="updateMode == false"
                                @click="resetItems(statePrefix)"
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-7 pa-0">
                        <v-row justify="end">
                            <v-col cols="1" lg="3" md="3" xs="3" class="px-1 pb-0">
                                <div class="font-sm font-weight-black">{{ $t('message.netWeight') }}</div>
                                <div>
                                    <v-row no-gutters>
                                        <v-col class="col-lg-7-5 col-md-7 col-xs-6 mr-0">
                                            <v-text-field
                                                :placeholder="$t('message.netWeight')"
                                                :value="Pldo__netweight"
                                                class="custom pa-0 bg-lightgrey"
                                                dense
                                                hide-details
                                                required
                                                type="number"
                                                @change="Pldo__netweight = $event"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-lg-4-5 col-md-5 col-xs-6 mr-0">
                                            <v-select
                                                    :items="uofmWeights"
                                                    :value="Pldo__nwmeasurement_id ? Pldo__nwmeasurement_id : 53"
                                                    class="custom pa-0 bg-lightgrey"
                                                    dense
                                                    hide-details
                                                    item-text="Measurement.title"
                                                    item-value="Measurement.id"
                                                    v-model="Pldo__nwmeasurement_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="3" md="3" xs="3" class="px-1 pb-0">
                                <div class="font-sm font-weight-black">{{ $t('message.grossWeight') }}</div>
                                <div>
                                    <v-row no-gutters>
                                        <v-col class="col-lg-7-5 col-md-7 col-xs-6 mr-0">
                                            <v-text-field
                                                :placeholder="$t('message.grossWeight')"
                                                :value="Pldo__grossweight"
                                                class="custom pa-0 bg-lightgrey"
                                                dense
                                                hide-details
                                                required
                                                type="number"
                                                @change="Pldo__grossweight = $event"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col class="col-lg-4-5 col-md-5 col-xs-6 mr-0">
                                            <v-select
                                                    :items="uofmWeights"
                                                    :value="Pldo__gwmeasurement_id ? Pldo__gwmeasurement_id : 53"
                                                    class="custom pa-0 bg-lightgrey"
                                                    dense
                                                    hide-details
                                                    item-text="Measurement.title"
                                                    item-value="Measurement.id"
                                                    v-model="Pldo__gwmeasurement_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="font-sm font-weight-black">{{ $t('message.totalBundles') }}</div>
                                <div class="font-md font-weight-black">{{ formatThisNumber(itemsTotalBundles,'0,0') }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="font-sm font-weight-black">{{ $t('message.totalQty') }}</div>
                                <div class="font-md font-weight-black">{{ formatThisNumber(itemsTotalQty,'0,0') + ' Pcs' }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="itemsTotalArea.length > 0">
                                <div class="font-sm font-weight-black">{{ $t('message.totalArea') }}</div>
                                <div :class="(itemsTotalArea.length > 1 ? 'font-sm' : 'font-md') + ' font-weight-black d-flex flex-column'">
                                    <span v-for="(total,index) in itemsTotalArea" :class="itemsTotalArea.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.area, getUofmPrecision(total.Total.uofm))+ ' '+ total.Total.uofm }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="itemsTotalVolume.length > 0">
                                <div class="font-sm font-weight-black">{{ $t('message.totalVol') }}</div>
                                <div :class="(itemsTotalVolume.length > 1 ? 'font-sm' : 'font-md') + ' font-weight-black d-flex flex-column'">
                                    <span v-for="(total,index) in itemsTotalVolume" :class="itemsTotalVolume.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.volume, getUofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {getSafe, numberFormat} from "../../helpers/helpers";
    import { debounce } from "debounce"
    import { api } from "../../api";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed, mapGettersPrefixed} from "../../helpers/vuex-map-fields-prefixed";

    export default {
        name: "PackingListItems",
        props: ['updateMode'],
        data(){
            return {
                currentRowIndex: 0,
                dialogs: {
                    confirm:  false,
                    confirm_message: ""
                },
                fieldsWithApi: ['PackingListItem.grade_id'],
                filteredGrades: [],
                loadingGrades: [],
                maxNumberLength: 1000000,
                minNumberLength: 0,
                pageKey: 0,
                resettingPackingListItems: false,
                salesDescriptions: [],
                searchGrades: [],
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapFieldsPrefixed('packinglist',{
                Pldo__id: 'Pldo.id',
                Pldo__grossweight: 'Pldo.grossweight',
                Pldo__gwmeasurement_id: 'Pldo.gwmeasurement_id',
                Pldo__netweight: 'Pldo.netweight',
                Pldo__nwmeasurement_id: 'Pldo.nwmeasurement_id'
            },'statePrefix'),
            ...mapGettersPrefixed('packinglist',{
                Pldo__Containers: 'Pldo.Containers',
                Pldo__Salesdescriptions: 'Pldo.Salesdescriptions'
            },'statePrefix'),
            ...mapMultiRowFieldsPrefixed('packinglistitem',{
                PackingListItems: 'PackingListItems'
            },'statePrefix'),
            ...mapFieldsPrefixed('packinglistitem',{
                itemsTotalArea: 'itemsTotalArea',
                itemsTotalBundles: 'itemsTotalBundles',
                itemsTotalQty: 'itemsTotalQty',
                itemsTotalVolume: 'itemsTotalVolume',
            },'statePrefix'),
            ...mapFields('packinglist',{
                newPackingListSaved: 'newPackingListSaved',
                newPackingListUnsaved: 'newPackingListUnsaved',
                updatedPackingListSaved: 'updatedPackingListSaved',
                updatedPackingListUnsaved: 'updatedPackingListUnsaved',
            }),
            ...mapGetters([
                'uofmAreas',
                'uofmDimensions',
                'uofmQty',
                'uofmVolumes',
                'uofmWeights'
            ]),
            console: () => console,
            headers() {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.container'),
                        value: 'PackingListItem.container_id',
                    },
                    {
                        id: 1,
                        text: this.$t('message.specification'),
                        value: 'PackingListItem.salesdescription_id',
                    },
                    {
                        id: 2,
                        text: this.$t('message.gradeAndType'),
                        value: 'PackingListItem.grade_id',
                    },
                    // {
                    //     id: 3,
                    //     text: this.$t('message.type'),
                    //     value: 'PackingListItem.type',
                    // },
                    {
                        id: 3,
                        text: this.$t('message.size'),
                        value: 'PackingListItem.thickness',
                    },
                    // {
                    //     id: 4,
                    //     text: this.$t('message.width'),
                    //     value: 'PackingListItem.width',
                    // },
                    // {
                    //     id: 5,
                    //     text: this.$t('message.length'),
                    //     value: 'PackingListItem.length',
                    // },
                    {
                        id: 6,
                        text: this.$t('message.bundles'),
                        value: 'PackingListItem.bundles',
                    },
                    {
                        id: 7,
                        text: this.$t('message.pieces'),
                        value: 'PackingListItem.pieces',
                    },
                    {
                        id: 8,
                        text: this.$t('message.area'),
                        value: 'PackingListItem.area',
                    },
                    {
                        id: 9,
                        text: this.$t('message.volume'),
                        value: 'PackingListItem.volume',
                    },
                    {
                        id: 10,
                        text: this.$t('message.actions'),
                        value: 'index',
                    },
                ]
                return headers
            }
        },
        methods: {
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('packinglist',{
                getSalesdescriptionsByShipment: 'getSalesdescriptionsByShipment'
            }),
            ...mapActions('packinglistitem',{
                addPackingListItemRow: 'addPackingListItemRow',
                deletePackingListItem: 'deletePackingListItem',
                resetPackingListItems: 'resetPackingListItems',
                updatePackingListItems: 'updatePackingListItems',
            }),
            ...mapMutations('packinglistitem',{
                updateTotals: 'updateTotals',
                deletePackingListItemRow: 'deletePackingListItemRow'
            }),
            async deleteItem(itemIndex, itemId) {
                let _this = this
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: this.statePrefix
                        }
                        this.deletePackingListItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=>{
                        if(itemId != null){
                            this.deletePackingListItem({
                                packinglist_id: this.Pldo__id,
                                packinglistitem_id: itemId}
                            )
                            .then((response)=>{
                                _this.updateTotals(_this.statePrefix)
                            })
                        } else {
                            _this.updateTotals(_this.statePrefix)
                        }
                    })
                    .finally(()=>{
                        _this.updateTotals(_this.statePrefix)
                    })
                }
            },
            doGradesSearch: async(rowIndex, value, self) => {
                if(!value){
                    self.filteredGrades[rowIndex] = [];
                }
                if(self.loadingGrades[rowIndex]){
                    return
                }
                self.loadingGrades[rowIndex] = true
                await api
                    .get("/grades", {
                        params: {
                            conditions: [
                                {
                                    field: 'grades.title',
                                    value: value,
                                    textsearch: true
                                }
                            ]
                        }
                    })
                    .then(
                        response => {
                            if (response.data.data.length > 0) {
                                self.filteredGrades[rowIndex] = response.data.data
                            } else {
                                self.filteredGrades[rowIndex] = []
                            }
                        }
                    ).catch(error => {
                            self.error = this.$t('message.unknownError')
                            self.filteredGrades[rowIndex] = []
                        }
                    ).finally(
                        () => (self.loadingGrades[rowIndex] = false)
                    )
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getUofmPrecision(uofm){
                let precision = this.uofmQty.find((u)=> u.Measurement.title == uofm?.trim())
                if(precision){
                    return precision.Measurement.formatPrecision
                } else {
                    return '0,0.00'
                }
            },
            async resetItems() {
                if (await this.$root.$confirm(this.$t('message.resetItems'), this.$t('message.confirmations.continueItemsReset'), {color: 'orange'})) {
                    this.resettingPackingListItems = true
                    this.resetPackingListItems(this.statePrefix)
                        .then(() => {
                            setTimeout(() => {
                                this.resettingPackingListItems = false
                                this.$toast.success(this.$t('message.packingListItemsResetNotification'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            }, 1000)
                        })
                }
            },
            updatePackingListItemRowHandle(idx,field,value) {
                if(this.fieldsWithApi.includes(field)){
                    switch(field) {
                        case 'PackingListItem.grade_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value.Grade.id
                            }
                            // assign Grade property in Stock
                            this.$store.commit('packinglistitem/updatePackingListItems', {
                                index: idx,
                                field: 'Grade.id',
                                prefix: this.statePrefix,
                                value: value.Grade.id != null ? value.Grade.id : null
                            })
                            this.$store.commit('packinglistitem/updatePackingListItems', {
                                index: idx,
                                field: 'Grade.title',
                                prefix: this.statePrefix,
                                value: value.Grade.title != null ? value.Grade.title : null
                            })
                            break;
                    }
                } else {
                    switch(field) {
                        case 'PackingListItem.container_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value.Container?.id ? value.Container?.id : value.Container?.title,
                            }
                            if (value != null) {
                                //TODO check effect on CREATE PACKING LIST mode
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'PackingListItem.container',
                                    prefix: this.statePrefix,
                                    value: value.Container?.title != null ? value.Container?.title : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Container.title',
                                    prefix: this.statePrefix,
                                    value: value.Container?.title != null ? value.Container?.title : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Container.sealno',
                                    prefix: this.statePrefix,
                                    value: value.Container?.sealno != null ? value.Container?.sealno : null
                                })
                            }
                            break;
                        case 'PackingListItem.salesdescription_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            let salesdescription = this.Pldo__Salesdescriptions.filter(s => s.Salesdescription.id === value)[0];
                            if (salesdescription != null) {
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Salesdescription.id',
                                    prefix: this.statePrefix,
                                    value: salesdescription.Salesdescription.id != null ? salesdescription.Salesdescription.id : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Salesdescription.title',
                                    prefix: this.statePrefix,
                                    value: salesdescription.Salesdescription.title != null ? salesdescription.Salesdescription.title : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Salesdescription.species_id',
                                    prefix: this.statePrefix,
                                    value: salesdescription.Salesdescription.species_id != null ? salesdescription.Salesdescription.species_id : null
                                })
                            }
                            break;
                        case 'PackingListItem.ameasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            let ameasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if (ameasurement != null) {
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Ameasurement.id',
                                    prefix: this.statePrefix,
                                    value: ameasurement.Measurement.id != null ? ameasurement.Measurement.id : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Ameasurement.title',
                                    prefix: this.statePrefix,
                                    value: ameasurement.Measurement.title != null ? ameasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'PackingListItem.smeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            let smeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if (smeasurement != null) {
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Smeasurement.id',
                                    prefix: this.statePrefix,
                                    value: smeasurement.Measurement.id != null ? smeasurement.Measurement.id : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Smeasurement.title',
                                    prefix: this.statePrefix,
                                    value: smeasurement.Measurement.title != null ? smeasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'PackingListItem.wmeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            let wmeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if (wmeasurement != null) {
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Wmeasurement.id',
                                    prefix: this.statePrefix,
                                    value: wmeasurement.Measurement.id != null ? wmeasurement.Measurement.id : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Wmeasurement.title',
                                    prefix: this.statePrefix,
                                    value: wmeasurement.Measurement.title != null ? wmeasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'PackingListItem.lmeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            let lmeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if (lmeasurement != null) {
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Lmeasurement.id',
                                    prefix: this.statePrefix,
                                    value: lmeasurement.Measurement.id != null ? lmeasurement.Measurement.id : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'Lmeasurement.title',
                                    prefix: this.statePrefix,
                                    value: lmeasurement.Measurement.title != null ? lmeasurement.Measurement.title : null
                                })
                            }
                            break;
                        case 'PackingListItem.shippedmeasurement_id':
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                            //assign Measurement property in
                            let soldmeasurement = this.uofmDimensions.filter(uofm => uofm.Measurement.id === value)[0];
                            if (soldmeasurement != null) {
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'ShippedMeasurement.id',
                                    prefix: this.statePrefix,
                                    value: soldmeasurement.Measurement.id != null ? soldmeasurement.Measurement.id : null
                                })
                                this.$store.commit('packinglistitem/updatePackingListItems', {
                                    index: idx,
                                    field: 'ShippedMeasurement.title',
                                    prefix: this.statePrefix,
                                    value: soldmeasurement.Measurement.title != null ? soldmeasurement.Measurement.title : null
                                })
                            }
                            break;
                        default:
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value,
                            }
                    }
                }
                let promise = new Promise((resolve, reject) => {
                    this.$store.commit('packinglistitem/updatePackingListItems', payload)
                    resolve('ok')
                });
                let _this = this
                promise.then(_this.updateTotals(this.statePrefix))
            },
        },
        watch: {
            searchGrades(value){
                if(value[this.currentRowIndex] == null ||  value[this.currentRowIndex].length == ''){
                    return
                }
                debounce(this.doGradesSearch,300)(this.currentRowIndex, value[this.currentRowIndex],this)
            },
            Pldo__Salesdescriptions(values) {
                this.salesDescriptions = values
            }
        },
        created() {
            if(this.allGrades.length == 0) this.getAllGrades()
            this.salesDescriptions = this.Pldo__Salesdescriptions
        },
        mounted(){
            this.filteredGrades = []
        }
    }
</script>

<style>
    .bg-lightgrey {
        background-color: #F5F5F5;
    }
    .col-lg-4-5 {
        flex: 0 0 37.5;
        max-width: 37.5% !important;
    }
    .col-lg-7-5 {
        flex: 0 0 62.5;
        max-width: 62.5% !important;
    }
    .v-text-field__slot input {
        text-align: right;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-data-table td {
        padding: 0 5px;
    }
    .font-xs {
        font-size: 0.75rem;
    }
    .force-text-left {
        text-align: left !important;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .custom.v-select .v-icon {
        font-size: 18px !important;
    }
    .truncate {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table tbody td {
        vertical-align: top !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 40px !important;
    }
    .dense-inputs .v-input {
        font-size: 1.0em !important;
        padding-top: 0px !important;
    }
</style>